import React from 'react';
import { Box } from '@mui/material';
import colors from '../../config/colors';

const PositionBox = ({ position, customStyles }) => (
  <Box 
    sx={{ 
      display: 'inline-flex',                // Changed to flex
      justifyContent: 'center',       // Center content horizontally
      alignItems: 'center',           // Center content vertically
      margin: {
        xs: '1px',
        sm: '1.5px',
        md: '2px',
      },
      backgroundColor: colors[position] || colors.defaultPositionColor, 
      borderRadius: '4px', 
      fontSize: {
        xs: '0.65rem', // smaller font size on extra small devices
        sm: '0.65rem', // small devices
        md: '0.85rem', // medium devices (default)
      },
      color: 'white',
      padding: {
        xs: '2px 4px',
        sm: '2px 4px',
        md: '4px 8px',
      },
      width: '14px',
      ...customStyles,
    }}
  >
    {position}
  </Box>
);

export default PositionBox;
