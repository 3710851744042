import React from 'react';
import { Box, Container, Typography } from '@mui/material';
import { Link } from 'react-router-dom';

const BottomBar = ({ isMobile }) => {
  return (
    <Box
      sx={{
        width: '100%', // Adjust the width based on the screen size
        backgroundColor: '#f5f5f5',
        padding: '10px 0',
        boxShadow: '0 -2px 5px rgba(0, 0, 0, 0.1)',
        fontFamily: 'Avenir, Arial, sans-serif',
      }}
    >
      <Container maxWidth="lg" sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
        <Typography 
          variant="body2" 
          color="textSecondary"
          sx={{
            fontSize: {
              xs: '0.6rem', // smaller text for extra-small screens
              sm: '0.8rem', // default text size for small screens and up
              md: '1rem', // larger text for medium screens and up
            }
          }}
        >
          © {new Date().getFullYear()} HarryKnowsBall.com. All rights reserved.
        </Typography>
        <Box>
        <Typography component={Link} to="/privacy-policy" 
          sx={{ 
            color: 'inherit', 
            textDecoration: 'none', 
            marginRight: '15px', 
            fontSize: { xs: '0.6rem', sm: '0.8rem', md: '1rem' }
          }}>
          Privacy Policy
        </Typography>
        <Typography component={Link} to="/terms-of-service" 
          sx={{ 
            color: 'inherit', 
            textDecoration: 'none', 
            fontSize: { xs: '0.6rem', sm: '0.8rem', md: '1rem' } 
          }}>
          Terms of Use
        </Typography>
      </Box>
      </Container>
    </Box>
  );
};

export default BottomBar;
