import React, { useState } from 'react';
import {
  AreaChart,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
  Area
} from 'recharts';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import FloatingSelectionFilter from '../Filter/FloatingSelectionFilter';
import colors from '../../config/colors';

const ValueHistoryChart = ({ data }) => {

    const [timeScale, setTimeScale] = useState('All');

    const theme = useTheme();
    const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm'));

    const now = new Date();
    let filteredData = data;
  
    if (timeScale !== 'All') {
      const filterDate = (days) => {
        const pastDate = new Date(now);
        pastDate.setDate(now.getDate() - days);
        return pastDate;
      };
  
      const scales = {
        '1 Year': filterDate(365),
        '1Y': filterDate(365),
        '6 Months': filterDate(183),
        '6M': filterDate(183),
        '1 Month': filterDate(30),
        '1M': filterDate(30),
        '1 Week': filterDate(7),
        '1W': filterDate(7),
      };
  
      const filterByDate = scales[timeScale];
      filteredData = data.filter((entry) => new Date(entry.date) >= filterByDate);
    }

    const handleTimeScaleChange = (value) => {
        if (value && value !== timeScale) {
            setTimeScale(value);
        }
    };

    const renderYearLabels = () => {
        const uniqueYears = Array.from(
            new Set(filteredData.map((entry) => new Date(entry.date).getFullYear()))
        );
        return uniqueYears.map((year) => (
            <text
            key={year}
            x={`${
                (filteredData.findIndex(
                (entry) => new Date(entry.date).getFullYear() === year
                ) /
                filteredData.length) *
                100
            }%`}
            y={270}
            textAnchor="middle"
            fontSize={12}
            fill="rgba(0, 0, 0, 0.5)"
            >
            {year}
            </text>
        ));
    };

    const formatXAxis = (tickItem) => {
    const date = new Date(tickItem);
    const month = (date.getMonth() + 1).toString().padStart(2, '0');
    const day = date.getDate().toString().padStart(2, '0');
    return `${month}-${day}`;
    };

    const minValue = Math.min(...filteredData.map((entry) => entry.value));
    const maxValue = Math.max(...filteredData.map((entry) => entry.value));
    const minY = Math.max(0, Math.floor((minValue - 100) / 100) * 100);
    const maxY = Math.min(10000, Math.ceil((maxValue + 100) / 100) * 100);

    const CustomTooltip = ({ active, payload, label }) => {
        if (active && payload && payload.length) {
          return (
            <div
              style={{
                backgroundColor: '#333',
                border: '1px solid #444',
                padding: isSmallScreen ? '5px' : '10px',
                borderRadius: '10px',
                fontSize: isSmallScreen ? '10px' : '14px',
                color: '#fff',
                boxShadow: '0 4px 8px rgba(0, 0, 0, 0.3)',
              }}
            >
              <p
                style={{
                  color: colors.secondary400,
                  margin: '0 0 5px 0',
                  fontWeight: 'bold',
                }}
              >{`${label}`}</p>
              <p style={{ margin: '0' }}>{`Value: ${payload[0].value}`}</p>
              <p style={{ margin: '0' }}>{`Rank: ${payload[0].payload.rank}`}</p>
            </div>
          );
        }
    
        return null;
      };

    return (
        <>
        {data.length > 0 && (
            <>
            <FloatingSelectionFilter
                options={isSmallScreen ? ['1W', '1M', '6M', 'All'] : ['1 Week', '1 Month', '6 Months', 'All']}
                selectedOption={timeScale}
                handleSelect={handleTimeScaleChange}
            />
                <ResponsiveContainer width="100%" height={isSmallScreen ? 210 : 320} style={{ marginLeft: '-24px', paddingTop: '4px' }}>
                    <AreaChart data={filteredData}>
                        <CartesianGrid strokeDasharray="3 3" stroke="rgba(200, 200, 200, 0.5)" />
                        <XAxis
                            dataKey="date"
                            tick={{ fontSize: 12, fill: 'rgba(0, 0, 0, 0.5)' }}
                            tickFormatter={formatXAxis}
                        >
                            {renderYearLabels()}
                        </XAxis>
                        <YAxis
                            domain={[minY, maxY]}
                            tick={{ fontSize: 12, fill: 'rgba(0, 0, 0, 0.5)' }}
                            tickFormatter={(value) => value.toLocaleString()}
                            tickLine={false}
                            axisLine={false}
                            tickMargin={0}
                        />
                        <Tooltip content={<CustomTooltip />} />
                        <Legend />
                        <defs>
                            <linearGradient id="solidLineGradient" x1="0" y1="0" x2="0" y2="1">
                                <stop offset="10%" stopColor={colors.primary400} stopOpacity={1} />
                                <stop offset="100%" stopColor={colors.primary400} stopOpacity={0.5} />
                            </linearGradient>
                        </defs>
                        <Area
                            type="monotone"
                            dataKey="value"
                            legendType="none"
                            stroke="url(#solidLineGradient)"
                            fill="url(#solidLineGradient)"
                            strokeWidth={4}
                            dot={false}
                            animationDuration={800}
                        />
                    </AreaChart>
                </ResponsiveContainer>
            </>
        )}
    </>
  );
};

export default ValueHistoryChart;
