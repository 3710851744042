import { useState, useEffect } from 'react';
import { getLast100Selections } from '../api/apiService';

// Custom hook to fetch player rankings
const useAllPlayerSelections = () => {
  const [selections, setSelections] = useState([]);
  const [selectionsError, setSelectionsError] = useState(false);

  useEffect(() => {
    getLast100Selections()
      .then(response => setSelections(response.data))
      .catch(error => {
        console.error("Failed to fetch selections: ", error);
        setSelectionsError(true);
      });
  }, []);

  return { selections, selectionsError };
};

export default useAllPlayerSelections;
