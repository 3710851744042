import React from "react";
import { Box, Typography, Link as MuiLink, CircularProgress } from "@mui/material";
import { Link as RouterLink } from "react-router-dom";
import RankingsTopN from "../Leaderboard/RankingsTopN";

const RankingsPreview = ({ players, playersError, styles }) => {
  return (
    <Box 
      sx={{
        my: { xs: "10px", sm: "15px", md: "20px" },
        padding: "20px",
        display: "flex", 
        flexDirection: "column", 
        alignItems: "center"
      }}
    >
      <Typography 
        component="h2" 
        sx={{ 
          ...styles.sectionTitle,
          textAlign: "center", 
          marginBottom: { xs: "25px", sm: "30px", md: "35px" },
          fontFamily: "Avenir, sans-serif"
        }}
      >
        Value Leaderboard
      </Typography>

      {players && players.length > 0 ? (
        <>
          {/* Main Content */}
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              gap: 2, // Space between sections
              width: "60%",
              maxWidth: "lg",
              margin: "0 auto", // Center content horizontally
            }}
          >
            <Box
              sx={{
                width: "100%",
                display: "flex",
                justifyContent: "center",
              }}
            >
              <RankingsTopN players={players} count={5} effectEnabled={true} />
            </Box>
          </Box>

          <Box sx={{ marginTop: "30px" }}>
            <Typography
              variant="body1"
              sx={{
                textAlign: "center",
                fontFamily: "Avenir, sans-serif",
                fontSize: { xs: ".7rem", sm: ".8rem", md: ".9rem" }
              }}
            >
              Check out our full rankings{" "}
              <MuiLink
                component={RouterLink}
                to="/rankings"
                sx={{ fontWeight: "bold", textDecoration: "none" }}
              >
                here
              </MuiLink>
              !
            </Typography>
          </Box>
        </>
      ) : (
        // CircularProgress spinner while loading
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            gap: 2,
            justifyContent: "center",
            minHeight: "150px", // Ensures a minimum height for spinner area
          }}
        >
          <CircularProgress color="inherit" />
        </Box>
      )}
    </Box>
  );
};

export default RankingsPreview;
