import React from 'react';
import { Typography, Link, Box } from '@mui/material';
import Title from '../../components/Title/Title';
import colors from '../../config/colors';

const TermsOfUsePage = () => {

  return (
    <Box className="terms-of-service" sx={{ fontFamily: 'Avenir, sans-serif' }}>
        <Title pageName="Terms of Service" showPickerLink={false} showRankingsLink={false} />

      <Typography variant="body2" gutterBottom sx={{ fontFamily: 'Avenir, sans-serif' }}>
        <strong>Effective Date: </strong>November 13, 2024
      </Typography>

      <Typography variant="body1" paragraph sx={{ fontFamily: 'Avenir, sans-serif' }}>
        Welcome to <strong>HarryKnowsBall.com</strong>. By accessing or using our website, you agree to comply with and be bound by the following Terms of Service. Please review the terms carefully. If you do not agree with these terms, you should not use our website.
      </Typography>

      <Typography variant="h6" color={colors.primary400} sx={{ fontFamily: 'Avenir, sans-serif' }}>
        1. Services Provided
      </Typography>
      <Typography variant="body1" paragraph sx={{ fontFamily: 'Avenir, sans-serif' }}>
        HarryKnowsBall.com provides fantasy baseball rankings and tools intended to enhance your experience in fantasy baseball leagues. All content and tools are offered for informational and entertainment purposes only.
      </Typography>

      <Typography variant="h6" color={colors.primary400} sx={{ fontFamily: 'Avenir, sans-serif' }}>
        2. User Responsibilities
      </Typography>
      <Typography variant="body1" paragraph sx={{ fontFamily: 'Avenir, sans-serif' }}>
        Users are expected to use the tools and rankings provided on HarryKnowsBall.com responsibly. Specifically, users should not submit or share fantasy baseball rankings with malicious intent, including but not limited to manipulating rankings to deceive other users or disrupt the website's operations.
      </Typography>
      <Typography variant="body1" paragraph sx={{ fontFamily: 'Avenir, sans-serif' }}>
        The trade calculator tool provided on HarryKnowsBall.com is intended solely for use on this website. Any duplication, redistribution, or external use of the trade calculator's underlying logic, algorithms, or code is strictly prohibited without explicit permission from HarryKnowsBall.com. Users may freely use and share the results generated by the calculator but may not replicate the tool’s logic outside of the website.
      </Typography>

      <Typography variant="h6" color={colors.primary400} sx={{ fontFamily: 'Avenir, sans-serif' }}>
        3. Data Handling
      </Typography>
      <Typography variant="body1" paragraph sx={{ fontFamily: 'Avenir, sans-serif' }}>
        HarryKnowsBall.com does not collect, store, or use any personal data from users. We respect your privacy and are committed to maintaining a data-free environment.
      </Typography>

      <Typography variant="h6" color={colors.primary400} sx={{ fontFamily: 'Avenir, sans-serif' }}>
        4. Liability Disclaimer
      </Typography>
      <Typography variant="body1" paragraph sx={{ fontFamily: 'Avenir, sans-serif' }}>
        The content on HarryKnowsBall.com is intended for informational and entertainment purposes only. We are not responsible for any costs incurred from using the content on this site, including but not limited to league dues or any other fantasy baseball-related expenses. Users acknowledge that they use the website and its tools at their own risk.
      </Typography>

      <Typography variant="h6" color={colors.primary400} sx={{ fontFamily: 'Avenir, sans-serif' }}>
        5. Governing Law
      </Typography>
      <Typography variant="body1" paragraph sx={{ fontFamily: 'Avenir, sans-serif' }}>
        These Terms of Service shall be governed by and construed in accordance with the laws of the State of Texas, without regard to its conflict of law principles. Any legal action or proceeding related to these terms shall be brought exclusively in a court of competent jurisdiction located in Texas.
      </Typography>

      <Typography variant="h6" color={colors.primary400} sx={{ fontFamily: 'Avenir, sans-serif' }}>
        6. Changes to Terms
      </Typography>
      <Typography variant="body1" paragraph sx={{ fontFamily: 'Avenir, sans-serif' }}>
        We reserve the right to update or modify these Terms of Service at any time without prior notice. The date of the most recent update will be indicated at the top of this document. Continued use of the website after any such changes constitutes your acceptance of the new terms.
      </Typography>

      <Typography variant="h6" color={colors.primary400} sx={{ fontFamily: 'Avenir, sans-serif' }}>
        7. Contact Information
      </Typography>
      <Typography variant="body1" paragraph sx={{ fontFamily: 'Avenir, sans-serif' }}>
        If you have any questions or concerns regarding these Terms of Service, please contact us via email at <Link href="mailto:harryknowsballteam@gmail.com">harryknowsballteam@gmail.com</Link>.
      </Typography>
    </Box>
  );
};

export default TermsOfUsePage;
