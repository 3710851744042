import React from 'react';
import { AppBar, Toolbar, Box, IconButton, Fade } from '@mui/material';
import MenuIcon from '@mui/icons-material/Menu';
import CloseIcon from '@mui/icons-material/Close';
import logoImage from '../../assets/Slide1.png';
import { Link } from 'react-router-dom';

const TopbarMobile = ({ toggleSidebar, sidebarVisible }) => {

  const handleToggleSidebar = () => {
    toggleSidebar();
  };

  return (
    <AppBar position="fixed" color="inherit" elevation={0} sx={{ borderBottom: '1px solid #e0e0e0', height: '76px' }}>
      <Toolbar>
        <Box
          sx={{
            flexGrow: 1,
            display: 'flex',
            justifyContent: 'center',
          }}
        >
          <Link to="/" style={{ display: 'flex', alignItems: 'center' }}>
            <img src={logoImage} alt="Logo" style={{ maxHeight: 76 }} />
          </Link>
        </Box>
        <IconButton
          edge="start"
          color="black"
          aria-label="menu"
          sx={{ p: 2, "& .MuiSvgIcon-root": { fontSize: "2rem" } }}
          onClick={handleToggleSidebar}
        >
          <Fade in={!sidebarVisible} timeout={300}>
            <MenuIcon style={{ position: 'absolute' }} />
          </Fade>
          <Fade in={sidebarVisible} timeout={300}>
            <CloseIcon style={{ position: 'absolute' }} />
          </Fade>
        </IconButton>
      </Toolbar>
    </AppBar>
  );
};

export default TopbarMobile;
