import React, { useState, useEffect } from 'react';
import { Modal, Box, Button, Typography, Grid } from '@mui/material';
import { generateTwoSelection, rankTwoPlayers } from '../../../api/apiService';
import useUserActivity from '../../../hooks/useUserActivity';

const modalStyle = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: '83vw', // Adjusted for mobile
  maxWidth: '600px',
  bgcolor: 'background.paper',
  border: '2px solid #000',
  boxShadow: 24,
  p: {'xs': 2, 'sm': 4}, // Reduced padding for mobile
  overflow: 'auto',
  maxHeight: '90vh',
};

const TwoPlayerModal = () => {
  const [open, setOpen] = useState(false);
  const [players, setPlayers] = useState([]);
  const [selectedPlayer, setSelectedPlayer] = useState(null);
  const [notSelectedPlayer, setNotSelectedPlayer] = useState(null);
  const isSubmitDisabled = !selectedPlayer;
  const { isActive } = useUserActivity();

  useEffect(() => {
    generateSelection();
  }, []);

  useEffect(() => {
    const randomDelay = Math.random() * (30000 - 25000) + 25000;
    const timer = setTimeout(() => {
      setOpen(true);
    }, randomDelay);
    return () => clearTimeout(timer);
  }, []);

  useEffect(() => {
    if (!open && isActive) {
      generateSelection();
      const reopenTimer = setTimeout(() => {
        setOpen(true);
      }, 200000);
      return () => clearTimeout(reopenTimer);
    }
  }, [open, isActive]);

  const handleSubmit = () => {
    const payload = {
      favored: selectedPlayer,
      notFavored: notSelectedPlayer,
    };

    rankTwoPlayers(payload)
      .then((response) => {
        console.log(response);
      })
      .catch((error) => {
        console.error('There was an error sending the player rankings', error);
      });
      setOpen(false);
  };

  const generateSelection = () => {
    generateTwoSelection()
      .then((response) => {
        setPlayers(response.data);
        setSelectedPlayer(null);
        setNotSelectedPlayer(null);
      })
      .catch((error) => {
        console.error('There was an error fetching the player data', error);
      });
  };

  const handleClose = (event, reason) => {
    if (reason && reason === "backdropClick") 
        return;
    setOpen(false);
  }

  const selectPlayer = (selectedPlayer) => {
    setSelectedPlayer(selectedPlayer);
    const notSelected = players.find(player => player.id !== selectedPlayer.id);
    setNotSelectedPlayer(notSelected);
  };

  const renderPlayerButton = (player) => {
    const isSelected = selectedPlayer != null && selectedPlayer.id === player.id;
  
    return (
      <Button
        key={player.id}
        variant={isSelected ? "contained" : "outlined"}
        onClick={() => selectPlayer(player)}
        sx={{
          my: { xs: 0.6, sm: 0.8, md: 1 },
          width: '100%',
          justifyContent: 'flex-start',
          p: { xs: 1, sm: 1, md: 1.1 }
        }}
      >
        <Grid container spacing={2} alignItems="center">
          <Grid item xs={8}>
            <Typography
              variant="h6"
              component="div"
              sx={{
                fontFamily: 'Avenir, Arial, sans-serif',
                fontSize: { xs: '16px', sm: '17px', md: '19px' },
                textAlign: 'left',
                ml: { xs: .2, sm: .3, md: .4 },
                p: .1
              }}
            >
              {player.name}
            </Typography>
          </Grid>
          <Grid item xs={2}>
            <Typography
              variant="body1"
              sx={{
                fontFamily: 'Avenir, Arial, sans-serif',
                color: isSelected ? '#fff' : 'text.secondary',
                fontSize: { xs: '14px', sm: '15px', md: '17px' },
              }}
            >
              {player.age}
            </Typography>
          </Grid>
          <Grid item xs={2}>
            <Typography
              variant="body1"
              sx={{
                fontFamily: 'Avenir, Arial, sans-serif',
                color: isSelected ? '#fff' : 'text.secondary',
                fontSize: {xs: '14px', sm: '15px', md: '17px'},
              }}
            >
              {player.team}
            </Typography>
          </Grid>
        </Grid>
      </Button>
    );
  };
    

  return (
    <Modal
      open={open}
      onClose={handleClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box sx={{ 
        ...modalStyle, 
        position: 'relative',
        borderRadius: '10px',
        '&:focus-visible': {
          outline: 'none',
        },
      }}>
        <Typography
          id="modal-modal-title"
          variant="h6"
          component="h2"
          sx={{ fontFamily: 'Avenir, Arial, sans-serif', fontWeight: 'bold', mb: 1, fontSize: {xs: '20px', sm: '22px', md: '24px'} }}
        >
          Player Selection
        </Typography>
        <Typography
          variant="subtitle1"
          sx={{
            fontFamily: 'Avenir, Arial, sans-serif',
            color: 'text.secondary',
            mb: { xs: 1.5, sm: 1.5, md: 2 },
            fontSize: {xs: '14px', sm: '16px', md: '18px'}
          }}
        >
          Dynasty, head-to-head, standard points scoring. Your selection helps our rankings!
        </Typography>
        <Grid container spacing={0}>
          {players.map(player => (
            <Grid item xs={12} key={player.id}>
              {renderPlayerButton(player)}
            </Grid>
          ))}
        </Grid>
        <Button 
          variant="contained" 
          onClick={handleSubmit}
          disabled={isSubmitDisabled}
          sx={{
            fontFamily: 'Avenir, sans-serif',
            width: '100%',
            mt: { xs: 1.9, sm: 2, md: 2.1 },
            marginRight: '10px',
            fontSize: {xs: '15px', sm: '16px', md: '17px'},
            p: { xs: .6, sm: .65, md: .7 }
          }}
        >
            Submit
        </Button>
        <Button 
          variant="outlined" 
          onClick={handleClose} 
          sx={{
            fontFamily: 'Avenir, sans-serif',
            width: '100%',
            mt: { xs: 1.5, sm: 1.65, md: 1.8 },
            fontSize: {xs: '15px', sm: '16px', md: '17px'},
            p: { xs: .6, sm: .65, md: .7 }
          }}
        >
          I don't know both players
        </Button>
      </Box>
    </Modal>
  );
};

export default TwoPlayerModal;
