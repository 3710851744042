import React, { useState } from 'react';
import { Box, Button, Collapse } from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import PositionSelectionFilter from './PositionSelectionFilter';
import FloatingSelectionFilter from './FloatingSelectionFilter';
import colors from '../../config/colors';

const Filters = ({ selectedPositions, onPositionChange, levels, selectedLevel, onLevelChange, clearFilters }) => {
  const [open, setOpen] = useState(false);

  const handleToggleFilters = () => {
    setOpen(!open);
  };

  const dynamicTextSize = {
    fontSize: {
      xs: '0.65rem',
      sm: '0.75rem',
      md: '0.875rem',
    },
  };

  const filterButtonColor = selectedPositions.length > 0 || selectedLevel !== '' ? colors.secondary600 : 'primary.main';
  const borderColor = selectedPositions.length > 0 || selectedLevel !== '' ? colors.secondary550 : 'grey';
  const borderStyle = {
    border: open ? `1px solid ${borderColor}` : 'none',
    borderRadius: '4px',
  };

  const fontStyles = {
    fontFamily: 'Avenir, Arial, sans-serif',
    ...dynamicTextSize,
  };

  return (
    <Box sx={borderStyle}>
      <Box sx={{ display: 'flex', justifyContent: 'flex-start', alignItems: 'center', padding: '6px' }}>
        <Button 
          onClick={handleToggleFilters} 
          startIcon={<ExpandMoreIcon sx={{ transform: open ? 'rotate(180deg)' : 'rotate(0deg)' }} />} 
          sx={{ color: filterButtonColor, ...fontStyles }}
        >
          Filters
        </Button>
      </Box>
      <Collapse in={open}>
        <PositionSelectionFilter
          title="Position"
          selectedOptions={selectedPositions}
          onOptionChange={onPositionChange}
        />
        <FloatingSelectionFilter
          title="Level"
          options={levels}
          selectedOption={selectedLevel}
          handleSelect={onLevelChange}
        />
        <Box sx={{ display: 'flex', justifyContent: 'center', padding: '8px' }}>
          <Button onClick={clearFilters} sx={{ ...fontStyles, color: filterButtonColor }}>
            Clear
          </Button>
        </Box>
      </Collapse>
    </Box>
  );
};

export default Filters;
