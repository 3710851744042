import React from 'react';
import { Typography, Grid, Box } from '@mui/material';

const JWARPlayerDetails = ({ player }) => {

  return (
    <Box sx={{ paddingTop: '8px', paddingBottom: '8px' }}>
      <Grid container spacing={2}>
        {player.positions.map((position, index) => (
          player.jWARValues[index] !== 0 &&   
          <Grid item xs={12} sm={12} key={position}>
            <Box sx={{ bgcolor: 'white', fontSize: { xs: '0.75rem', sm: '0.85rem', md: '1rem' }, padding: 2, border: 1, borderColor: 'grey.300', borderRadius: 1 }}>
              <Box display="flex" alignItems="center">
                <Box sx={{ fontSize: { xs: '.8rem', sm: '.9rem', md: '1rem' }, borderRight: 1, borderColor: 'grey.400', paddingRight: 2, height: '100%', display: 'flex', alignItems: 'center' }}>
                  {position}
                </Box>
                <Box sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', paddingLeft: 2, borderRight: 1, borderColor: 'grey.400', paddingRight: 2, }}>
                  <Typography variant="subtitle1" sx={{ fontSize: 'inherit' }}>
                    jWAR: {player.jWARValues[index].toFixed(2)}
                  </Typography>
                  <Typography variant="subtitle1" sx={{ fontSize: 'inherit' }}>
                    {position} Rank: {player[`${position}_jWARRank`]}
                  </Typography>
                </Box>
                <Box sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', paddingLeft: 2 }}>
                  <Typography variant="subtitle1" sx={{ fontSize: 'inherit' }}>
                    jWARa: {player.jWARAValues[index].toFixed(2)}
                  </Typography>
                  <Typography variant="subtitle1" sx={{ fontSize: 'inherit' }}>
                  {position} Rank: {player[`${position}_jWARaRank`]}
                  </Typography>
                </Box>
              </Box>
            </Box>
          </Grid>
        ))}
      </Grid>
    </Box>
  );
  
};

export default JWARPlayerDetails;
