import { createTheme, ThemeProvider } from '@mui/material/styles';

const theme = createTheme({
  palette: {
    primary: {
      main: '#cc4949', // primary400
      light: '#db7272', // primary300
      dark: '#ba2525', // primary500
    },
    secondary: {
      main: '#fcde30', // secondary400
      light: '#fce874', // secondary300
      dark: '#fcd703', // secondary500
    },
    // You can define other colors such as success, warning, info, etc.
  },
});

export default theme;