import React, { useState, useEffect } from 'react';
import PlayerList from '../PlayerList/PlayerList';
import Filters from '../Filter/Filters';
import Title from '../Title/Title';
import { Alert, TextField, Box, IconButton, CircularProgress } from '@mui/material';
import { debounce } from 'lodash';
import ClearIcon from '@mui/icons-material/Clear';
import HeadWrapper from '../HeadWrapper/HeadWrapper';
import colors from '../../config/colors';

import logo from '../../assets/Rankings.png';

const Rankings = ({ players, error, lastUpdated }) => {
  const [displayedPlayers, setDisplayedPlayers] = useState([]);
  const [filterText, setFilterText] = useState('');
  const [selectedPositions, setSelectedPositions] = useState([]);
  const [selectedLevel, setSelectedLevel] = useState('');
  const [majorsFilter, setMajorsFilter] = useState(false);
  const [minorsFilter, setMinorsFilter] = useState(false);
  const [filtersApplied, setFiltersApplied] = useState(false);
  const [searchApplied, setSearchApplied] = useState(false);

  useEffect(() => {
    setDisplayedPlayers(players);
  }, [players]);

  useEffect(() => {
    const debounceFilter = debounce((searchText) => {
      const filtered = players.filter(player => {
        let playerPositionsArray = player.positions || [];
        let levelCheck = true;
        if((majorsFilter && (!player.majorLeaguer || player.assetType === "PICK")) || (minorsFilter && (player.majorLeaguer || player.assetType === "PICK"))) {
          levelCheck = false;
        }
        return player.name.toLowerCase().includes(searchText.toLowerCase()) &&
          (selectedPositions.length === 0 || playerPositionsArray.some(position => selectedPositions.includes(position))) &&
          levelCheck
      });
      setDisplayedPlayers(filtered);
      setFiltersApplied(selectedPositions.length > 0 || majorsFilter || minorsFilter);
      setSearchApplied(filterText && filterText.length > 0);
    }, 250);

    debounceFilter(filterText);
    return () => debounceFilter.cancel();
  }, [filterText, selectedPositions, selectedLevel, players, majorsFilter, minorsFilter]);

  const handlePositionChange = (event, newPosition) => {
    if (selectedPositions.includes(newPosition)) {
      setSelectedPositions(selectedPositions.filter(position => position !== newPosition));
    } else {
      setSelectedPositions([...selectedPositions, newPosition]);
    }
  };

  const handleLevelChange = (newLevel) => {
    setSelectedLevel(newLevel);
    if(newLevel === "Major Leaguers Only") {
      setMajorsFilter(true);
      setMinorsFilter(false);
    } else if(newLevel === "Minor Leaguers Only") {
      setMinorsFilter(true);
      setMajorsFilter(false);
    } else {
      setMajorsFilter(false);
      setMinorsFilter(false);
    }
  };

  const clearFilters = () => {
    setSelectedPositions([]);
    setSelectedLevel('');
    setMajorsFilter(false);
    setMinorsFilter(false);
  };

  const handleClearSearch = () => {
    setFilterText('');
  };

  const levels = ["Major Leaguers Only", "Minor Leaguers Only"];

  return (
    <>
      <HeadWrapper 
        title="Dynasty Baseball Rankings" 
        description="Crowd-sourced dynasty baseball rankings. We use 100% crowd-sourced player and pick values to give you the most accurate, up-to-date dynasty baseball rankings." 
        image={logo} 
      />
      <Box sx={{ mb: '6px', backgroundColor: 'white' }}>
        <Title pageName="Dynasty Baseball Rankings" extraText={`Crowd-sourced rankings. Last updated ${lastUpdated}`} showPickerLink={true} showRankingsLink={false}  />
        <Box sx={{ display: 'flex', justifyContent: 'center', marginBottom: '6px', flexDirection: 'column', alignItems: 'center', width: '100%' }}>
          {error && (
            <Alert severity="error" sx={{ marginBottom: 2, width: '100%' }}>Error retrieving rankings. Please check your internet connection or try again later.</Alert>
          )}
          <Box sx={{ position: 'relative', width: '100%' }}>
            <TextField
              label="Search Players"
              variant="outlined"
              value={filterText}
              onChange={(e) => setFilterText(e.target.value)}
              sx={{
                width: '100%',
                '& .MuiInputLabel-root': {
                  fontSize: { xs: '0.85rem', sm: '0.9rem', md: '1rem' },
                  fontFamily: 'Avenir, Arial, sans-serif',
                  fontWeight: 500
                },
                '& .MuiInputBase-input': {
                  fontSize: { xs: '0.8rem', sm: '0.9rem', md: '1rem' },
                  fontFamily: 'Avenir, Arial, sans-serif',
                  fontWeight: 500
                },
              }}
            />
            {filterText && 
              <IconButton
              size="small"
              onClick={handleClearSearch}
              sx={{
                position: 'absolute',
                right: 10,
                top: '50%',
                transform: 'translateY(-50%)',
              }}
            >
                <ClearIcon />
              </IconButton>
            }
          </Box>
        </Box>
        <Filters
            selectedPositions={selectedPositions}
            onPositionChange={handlePositionChange}
            levels={levels}
            selectedLevel={selectedLevel}
            onLevelChange={handleLevelChange}
            clearFilters={clearFilters}
          />
      </Box>

      {players && players.length > 0 ? (
        <PlayerList players={displayedPlayers} isFiltered={filtersApplied} isSearched={searchApplied} />
      ) : (
        !error && <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', minHeight: '200px' }}>
          <CircularProgress sx={{ color: colors.primary400 }} />
        </Box>
      )}
    </>
  );
};

export default Rankings;
