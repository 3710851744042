import React from 'react';
import { Box, Grid, Typography } from '@mui/material';
import HorizontalPlayerCard from './HorizontalPlayerCard';
import colors from '../../config/colors';

const getTimeAgo = (timestamp) => {
  const now = new Date();
  const diff = Math.floor((now - timestamp) / 1000);

  if (diff < 60) return "Just now";
  if (diff < 3600) {
    const minutes = Math.floor(diff / 60);
    return `${minutes} minute${minutes !== 1 ? 's' : ''} ago`;
  }
  if (diff < 86400) {
    const hours = Math.floor(diff / 3600);
    return `${hours} hour${hours !== 1 ? 's' : ''} ago`;
  }
  if (diff < 172800) return "Yesterday";
  if (diff < 604800) {
    const days = Math.floor(diff / 86400);
    return `${days} day${days !== 1 ? 's' : ''} ago`;
  }
  if (diff < 2419200) {
    const weeks = Math.floor(diff / 604800);
    return `${weeks} week${weeks !== 1 ? 's' : ''} ago`;
  }
  if (diff < 31536000) {
    const months = Math.floor(diff / 2419200);
    return `${months} month${months !== 1 ? 's' : ''} ago`;
  }

  const years = Math.floor(diff / 31536000);
  return `${years} year${years !== 1 ? 's' : ''} ago`;
};

const HorizontalSelectionList = ({ selections, isSmallScreen, playerId }) => {
  return (
    <Box sx={{ width: '100%', mx: 'auto', padding: 1, marginLeft: 1 }}>
      <Grid container spacing={isSmallScreen ? 1.5 : 3}  >
        {selections.map((selection, index) => (
          <Grid item xs={4} sm={4} md={4} key={index}>
            <Box
              sx={{
                borderRadius: 2,
                }}
            >
                <Box sx={{ px: 1, textAlign: 'left' }}>
                    <Typography
                        variant="subtitle1"
                        sx={{
                            color: 'grey',
                            fontFamily: 'Avenir, Arial, sans-serif',
                            fontSize: { xs: '0.6rem', sm: '.75rem', md: '.9rem' },
                        }}
                    >
                        {getTimeAgo(new Date(selection.eventTimestamp))}
                    </Typography>
                </Box>

              <Box sx={{ marginTop: 1 }}>
                {selection.players
                  .slice()
                  .sort((a, b) => a.selectionIndex - b.selectionIndex)
                  .map((player, playerIndex) => (
                    <Box
                      key={playerIndex}
                      sx={{
                        display: 'flex',
                        alignItems: 'center',
                        gap: 1,
                        marginBottom: 1,
                      }}
                    >
                      <Box
                        sx={{
                          minWidth: { xs: 14, sm: 20, md: 28 },
                          height: { xs: 14, sm: 20, md: 28 },
                          fontSize: { xs: '.6rem', sm: '.7rem', md: '.8rem' },
                          borderRadius: '50%',
                          backgroundColor: colors.primary400,
                          border: '1px solid',
                          borderColor: colors.primary500,
                          display: 'flex',
                          alignItems: 'center',
                          justifyContent: 'center',
                          color: '#fff',
                          fontFamily: 'Avenir, Arial, sans-serif',
                          fontWeight: 'bold',
                          zIndex: 100,
                        }}
                      >
                        {playerIndex + 1}
                      </Box>
                      <HorizontalPlayerCard player={player} highlighted={playerId === player.playerId} />
                    </Box>
                  ))}
              </Box>
            </Box>
          </Grid>
        ))}
      </Grid>
    </Box>
  );
};

export default HorizontalSelectionList;
