import React, { useEffect, useState } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import {
  Alert,
  Card,
  CardContent,
  Typography,
  Grid,
  CircularProgress,
  Box,
  ToggleButtonGroup,
  ToggleButton,
  Link
} from '@mui/material';
import { getPlayerWithValueHistory, getLatest3SelectionsByPlayerId } from '../../api/apiService';
import ChangeCard from './ChangeCard';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import HeadWrapper from '../HeadWrapper/HeadWrapper';

import logo from '../../assets/Rankings.png';
import HorizontalSelectionList from './HorizontalSelectionList';
import ValueHistoryChart from './ValueHistoryChart';


const PlayerPage = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const [player, setPlayer] = useState(null);
  const [selections, setSelections] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(false);

  const theme = useTheme();
  const smallScreen = useMediaQuery(theme.breakpoints.down('md'));
  const extraSmallScreen = useMediaQuery(theme.breakpoints.down('sm'));
  const [timeFrame, setTimeFrame] = useState('30d');

  const levelMapping = {
    ROOKIE_BALL: "Rookie Ball",
    LOW_A: "Low A",
    HIGH_A: "High A",
    AA: "Double A",
    AAA: "Triple A",
    MLB: "MLB",
  };
  

  useEffect(() => {
    const payload = { id };
    getPlayerWithValueHistory(payload)
      .then((response) => {
        setPlayer(response.data);
        setLoading(false);
      })
      .catch(() => {
        setLoading(false);
        setError(true);
      });

    getLatest3SelectionsByPlayerId(payload)
    .then((response) => {
      setSelections(response.data);
      setLoading(false);
    })
    .catch(() => {
      setLoading(false);
      setError(true);
    });
  }, [id]);

  const handleToggle = (event, newTimeFrame) => {
    if (newTimeFrame) {
      setTimeFrame(newTimeFrame);
    }
  };

  if (!player || loading) {
    return <CircularProgress />;
  }

  if (error) {
    return <Alert severity="error" sx={{ marginTop: 2, marginBottom: 2, fontFamily: 'Avenir, Arial, sans-serif' }}>Error loading player data. Please check your internet connection or try again later.</Alert>
  }

  const { name, value, rank, fullValueHistory, playerInfo } = player;
  const { age, positions, team, level } = playerInfo;

  // Updated: Transforming fullValueHistory (list) into the format for recharts
  const data = fullValueHistory.map((entry) => ({
    date: entry.eventDate,
    value: entry.value,
    rank: entry.rank,
  }));

  const getRecentChange = (days) => {
    const recentDate = new Date();
    recentDate.setDate(recentDate.getDate() - days);
    const recentEntries = data.filter(entry => new Date(entry.date) >= recentDate);
    const initialEntry = recentEntries[0] || { value: value, rank: rank };
    const latestEntry = recentEntries[recentEntries.length - 1] || initialEntry;
    const valueChange = latestEntry.value - initialEntry.value;
    const rankChange = initialEntry.rank - latestEntry.rank;
    const valueChangePercentage = ((valueChange / initialEntry.value) * 100).toFixed(2);
    
    return {
      valueChange,
      valueChangePercentage,
      rankChange,
    };
  };

  const valueChange30Days = getRecentChange(30);
  const valueChange7Days = getRecentChange(7);
  const rankChange30Days = getRecentChange(30);
  const rankChange7Days = getRecentChange(7);

  const earliestDate = data.length > 0 ? new Date(Math.min(...data.map((entry) => new Date(entry.date)))) : null;
  const formattedEarliestDate = earliestDate ? earliestDate.toISOString().split('T')[0] : null;

  const displayValue = (changeValue) => changeValue === 0 ? '–' : changeValue;

  return (
    <>
    <HeadWrapper 
      title={`${name} Player Page`} 
      description={`Dynasty baseball trends and insights for ${name}. View player value history since ${formattedEarliestDate}.`} 
      image={logo} 
    />
    <Card style={{ boxShadow: 'none' }} >
      <CardContent>
        <Grid container alignItems="center" justifyContent="space-between"
          sx={{                
            borderBottom: '1px solid #ddd',
            paddingBottom: '10px',
            marginBottom: '10px'
          }}
        >
          <Grid item>
            <Typography
              variant="h5"
              component="div"
              sx={{
                fontFamily: 'Avenir, Arial, sans-serif',
                fontWeight: 'bold'
              }}
            >
              {name}
            </Typography>
          </Grid>
          {extraSmallScreen && <Grid item>
            <Link
              component="button"
              onClick={() => navigate(-1)}
              variant="subtitle2"
              sx={{ marginLeft: 'auto', textDecoration: 'none', paddingBottom: '0px', marginBottom: '-3px' }}
            >
              Back
            </Link>
          </Grid>}
        </Grid>
        <Grid container spacing={2}>
          <Grid item xs={6} sm={6}>
            <Typography color="textSecondary" sx={{ fontFamily: 'Avenir, Arial, sans-serif', fontWeight: 'bold' }}>
              Age: <span style={{ fontWeight: 'normal', fontFamily: 'Avenir, Arial, sans-serif' }}>{age ? age : 'NA'}</span>
            </Typography>
            <Typography color="textSecondary" sx={{ fontFamily: 'Avenir, Arial, sans-serif', fontWeight: 'bold' }}>
              Position: <span style={{ fontWeight: 'normal', fontFamily: 'Avenir, Arial, sans-serif' }}>{positions ? positions.join(", ") : 'NA'}</span>
            </Typography>
            <Typography color="textSecondary" sx={{ fontFamily: 'Avenir, Arial, sans-serif', fontWeight: 'bold' }}>
              Team: <span style={{ fontWeight: 'normal', fontFamily: 'Avenir, Arial, sans-serif' }}>{team ? team : 'NA'}</span>
            </Typography>
            <Typography color="textSecondary" sx={{ fontFamily: 'Avenir, Arial, sans-serif', fontWeight: 'bold' }}>
              Level: <span style={{ fontWeight: 'normal', fontFamily: 'Avenir, Arial, sans-serif' }}>
                {level ? levelMapping[level] || 'Unknown' : 'NA'}
              </span>
            </Typography>
          </Grid>
          <Grid item xs={6} sm={6}>
            <Typography color="textSecondary" fontFamily='Avenir, Arial, sans-serif' fontWeight="bold">
              Value: <span style={{ fontWeight: 'normal' }}>{value.toLocaleString()}</span>
            </Typography>
            <Typography color="textSecondary" fontFamily='Avenir, Arial, sans-serif' fontWeight="bold">
              Rank: <span style={{ fontWeight: 'normal' }}>{rank}</span>
            </Typography>
          </Grid>
        </Grid>

        {/* Quick Insights */}
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Box display="flex" justifyContent="space-between" alignItems="center">
              <Box   sx={{
                borderBottom: '1px solid #ddd',
                display: 'flex', // Enable flexbox layout
                justifyContent: 'space-between', // Space items evenly with equal gaps
                alignItems: 'center', // Align items vertically in the center
                width: '100%',
                paddingBottom: '6px',
                marginTop: '20px',
                marginBottom: '10px'
              }}>
                <Typography
                  variant="h6"
                  style={{ fontFamily: 'Avenir, Arial, sans-serif', fontWeight: 'bold', paddingLeft: '6px' }}
                >
                  Quick Insights
                </Typography>
                {extraSmallScreen && (
                  <ToggleButtonGroup
                    value={timeFrame}
                    exclusive
                    onChange={handleToggle}
                    size="small"
                    color="primary" // Use default MUI color
                    sx={{
                      marginRight: 1, // Use theme-based spacing
                      '& .MuiToggleButton-root': {
                        px: 1.5, // Adjust padding for compact buttons
                        py: 0.5, // Adjust vertical padding
                        fontSize: '0.6rem', // Smaller font size
                      },
                    }}
                  >
                    <ToggleButton value="7d">7 Days</ToggleButton>
                    <ToggleButton value="30d">30 Days</ToggleButton>
                  </ToggleButtonGroup>
                )}
              </Box>

            </Box>
            <Grid container spacing={2}>
              {(!extraSmallScreen || timeFrame === '7d') && (
                <>
                  <Grid item xs={6} sm={6} md={3}>
                    <ChangeCard
                      title="Value Change"
                      timeFrame="7 day"
                      changeValue={displayValue(valueChange7Days.valueChange)}
                    />
                  </Grid>
                  <Grid item xs={6} sm={6} md={3}>
                    <ChangeCard
                      title="Rank Change"
                      timeFrame="7 day"
                      changeValue={displayValue(rankChange7Days.rankChange)}
                    />
                  </Grid>
                </>
              )}
              {(!extraSmallScreen || timeFrame === '30d') && (
                <>
                  <Grid item xs={6} sm={6} md={3}>
                    <ChangeCard
                      title="Value Change"
                      timeFrame="30 day"
                      changeValue={displayValue(valueChange30Days.valueChange)}
                    />
                  </Grid>
                  <Grid item xs={6} sm={6} md={3}>
                    <ChangeCard
                      title="Rank Change"
                      timeFrame="30 day"
                      changeValue={displayValue(rankChange30Days.rankChange)}
                    />
                  </Grid>
                </>
              )}
            </Grid>
          </Grid>
        </Grid>
    
        {/* Value History */}
        <Grid container spacing={2} sx={{ paddingTop: '10px' }}>
          <Grid item xs={12}>
            <Typography
              variant="h6"
              style={{ fontFamily: 'Avenir, Arial, sans-serif', fontWeight: 'bold', marginTop: '20px', paddingLeft: '6px' }}
              sx={{ borderBottom: '1px solid #ddd', paddingBottom: '6px', marginBottom: '10px' }}
            >
              Value History
            </Typography>
            {data.length > 0 ? (
              <Typography
                variant="subtitle2"
                color="textSecondary"
                fontFamily='Avenir, Arial, sans-serif'
                style={{ paddingBottom: '10px', paddingLeft: '6px' }}
              >
                Value tracking for {name} began on {formattedEarliestDate}
              </Typography>
            ) : (
              <Typography
                variant="subtitle2"
                color="textSecondary"
                fontFamily='Avenir, Arial, sans-serif'
                style={{ paddingBottom: '10px', paddingLeft: '6px' }}
                >
                No value history for {name}
              </Typography>
            )}
            <ValueHistoryChart
              data={data}
              smallScreen={smallScreen}
            />
          </Grid>
        </Grid>

        {/* Previous Selections */}
        <Grid container spacing={2} sx={{ paddingTop: '10px' }}>
          <Grid item xs={12}>
            <Grid item>
              <Typography
                variant="h6"
                style={{ fontFamily: 'Avenir, Arial, sans-serif', fontWeight: 'bold', marginTop: '20px', paddingLeft: '6px' }}
                sx={{ borderBottom: '1px solid #ddd', paddingBottom: '6px', marginBottom: '10px' }}
              >
                Recent Selections
              </Typography>
              <Typography
                  variant="subtitle2"
                  color="textSecondary"
                  fontFamily='Avenir, Arial, sans-serif'
                  style={{ paddingBottom: '5px', paddingLeft: '6px' }}
                >
                  {selections && selections.length !== 0 ? `The most recent player selections involving ${name}` : `No recent player selections for ${name}`}
                </Typography>
            </Grid>
          </Grid>
          <HorizontalSelectionList selections={selections} isSmallScreen={smallScreen} playerId={id} />
        </Grid>
      </CardContent>
    </Card>
    </>
  );
};

export default PlayerPage;
