import React from 'react';
import { Box, ToggleButton, ToggleButtonGroup, Typography } from '@mui/material';
import colors from '../../config/colors'; // Adjust the import path as needed

const PositionSelectionFilter = ({ title, selectedOptions, onOptionChange }) => {
  const positions = ["C", "1B", "2B", "3B", "SS", "OF", "SP", "RP"];

  const isTouchDevice = () => {
    return 'ontouchstart' in window || navigator.maxTouchPoints > 0 || navigator.msMaxTouchPoints > 0;
  };

  const dynamicTitleSize = {
    fontSize: {
      xs: '0.75rem', // smaller text for extra-small devices
      sm: '0.875rem', // default text size for small devices and up
      md: '1rem', // slightly larger text for medium devices and up
    },
  };

  const fontStyles = {
    fontFamily: 'Avenir, Arial, sans-serif',
    fontWeight: '600'
  };

  const buttonStyles = {
    ...fontStyles,
    fontSize: 'clamp(11px, 2vw, 15px)',
    margin: '5px', // Added margin around each button
    borderRadius: '8px', // Applying slight rounding to all buttons
  };

  // Helper function to get hover, selected, and outline colors based on position and selection status
  const getButtonStyles = (position) => ({
    backgroundColor: selectedOptions.includes(position) ? colors[position] : `rgba(${hexToRgb(colors[position])}, .15)`, // Lower opacity for unselected positions
    color: selectedOptions.includes(position) ? '#fff' : '#5C5C5C', // Default to position color unless selected
    outline: `1px solid ${colors[position]}`, // Outline color matching the text color
    transition: 'background-color 0.3s ease, color 0.3s ease, outline-color 0.3s ease',
    ...(isTouchDevice() === false ? {
      ':hover': {
        backgroundColor: colors[`${position}_MuchLighter`], // Lighter color for hover state
        color: '#fff',
      },
    } : {
      ':hover': {
        backgroundColor: selectedOptions.includes(position) ? colors[position] : `rgba(${hexToRgb(colors[position])}, .15)`,
        color: selectedOptions.includes(position) ? '#fff' : '#5C5C5C',
      },
    }),
    '&.Mui-selected': {
      backgroundColor: colors[`${position}`], // Darker color for selected state
      color: '#fff',
      ...(isTouchDevice() === false ? {
        ':hover': {
          backgroundColor: colors[`${position}_Darker`], // Even darker color for hover over selected
        },
      } : {
        ':hover': {
          backgroundColor: colors[`${position}`],
        },
      }),
    }
  });

  // Helper function to convert hex color to RGB
  const hexToRgb = (hex) => {
    const bigint = parseInt(hex.slice(1), 16);
    const r = (bigint >> 16) & 255;
    const g = (bigint >> 8) & 255;
    const b = bigint & 255;
    return `${r}, ${g}, ${b}`;
  };

  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', width: '100%', alignItems: 'center' }}>
      <Box sx={{ display: 'flex', gap: 1, alignItems: 'center', justifyContent: 'center', flexDirection: 'column' }}>
        <Box sx={{ width: '100%', display: 'flex', justifyContent: 'flex-start', padding: '8px', paddingBottom: '4px' }}>
          <Typography variant="subtitle2" color="#5C5C5C" sx={{ ...dynamicTitleSize, ...fontStyles }}>
            {title}
          </Typography>
        </Box>
        <ToggleButtonGroup
          value={selectedOptions}
          onChange={onOptionChange}
          aria-label="option selection"
          exclusive
          sx={{
            '.MuiToggleButtonGroup-grouped': {
              borderRadius: '8px', // Ensuring all buttons in the group have rounded edges
              marginLeft: '0px',
            }
          }}
        >
          {positions.map((position) => (
            <ToggleButton
              key={position}
              value={position}
              aria-label={position}
              sx={{
                width: 'clamp(34px, 6vw, 50px)',
                height: 'clamp(35px, 6vw, 50px)',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                ...buttonStyles,
                ...getButtonStyles(position),
              }}
            >
              {position}
            </ToggleButton>
          ))}
        </ToggleButtonGroup>
      </Box>
    </Box>
  );
};

export default PositionSelectionFilter;
