import React from 'react';
import { Card, CardContent, Box, Typography } from '@mui/material';
import TrendingUpIcon from '@mui/icons-material/TrendingUp';
import TrendingDownIcon from '@mui/icons-material/TrendingDown';

import colors from '../../config/colors';

const ChangeCard = ({ title, timeFrame, changeValue }) => {

    const renderChangeIcon = (value) => {
        if (value === 0 || value === '–') {
          return null;
        }
        const iconProps = {
          sx: { fontSize: {xs: '35px', sm: '35px', md: '50px'} }, 
        };
        return value < 0 ? <TrendingDownIcon color="error" {...iconProps} /> : <TrendingUpIcon color="success" {...iconProps} />;
      };

  return (
    <Card sx={{ backgroundColor: colors.secondary100, border: 1, borderColor: colors.secondary500, borderRadius: 3, minHeight: {xs: '100px', sm: '100px', md: '130px'} }}>
      <CardContent sx={{ padding: 2, paddingTop: 1 }}>
        <Box display="flex" justifyContent="space-between" alignItems="center">
          <Typography
            variant="subtitle1"
            sx={{ fontFamily: 'Avenir, Arial, sans-serif', fontWeight: 'bold', fontSize: { xs: '.7rem', sm: '.8rem', md: '.9rem' } }}
            gutterBottom
          >
            {title}
          </Typography>
          <Typography
            variant="subtitle1"
            sx={{ fontFamily: 'Avenir, Arial, sans-serif', fontSize: { xs: '.7rem', sm: '.8rem', md: '.9rem' } }}
            gutterBottom
          >
            {timeFrame}
          </Typography>
        </Box>
        <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
          <Typography variant="h4" sx={{ fontSize: {xs: '1.7rem', sm: '1.7rem', md: '2rem'}, fontFamily: 'Avenir, Arial, sans-serif' }}>
            <span style={{ paddingRight: '8px' }}>{changeValue}</span>
            {renderChangeIcon(changeValue)}
          </Typography>
        </Box>
      </CardContent>
    </Card>
  );
};

export default ChangeCard;
